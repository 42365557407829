export default {
  "home": "Эхлэл",
  "yam": "Хөдөлмөр, нийгмийн хамгааллын яам. Бүх эрх хуулиар хамгаалагдсан.",
  "survey": "Судалгаа",
  "contact": "Холбоо барих",
  "system_management": "Систем удирдлага",
  "login": "Нэвтрэх",
  "location_map": "БАЙРШЛЫН ЗУРАГ",
  "email": "Цахим шуудан",
  "addrees_location": "Хаяг байршил",
  "phone": "Утас",
  "fill_survey": "Судалгааг бөглөх",
  "actual_survey": "Бодит судалгаа",
  "optimal": "Оновчтой бодлого",
  "back": "Буцах",
  "enter_your_information": "Өөрийн бүртгэлийн мэдээллийг оруулна уу.",
  "witout_registration": "Бүртгэлгүй бол?",
  "register": "Бүртгүүлэх",
  "forget_password": "Нууц үгээ мартсан уу?",
  "registration_number": "Регистрийн дугаарыг зөвхөн кириллээр бичих ёстойг анхаарна уу.",
  "personal_information": "Хувийн мэдээлэл",
  "date": "Огноо",
  "mission": "Эрхэм зорилго",
  "statistic": "Статистик",

  // Хувийн мэдээлэл
  "lastname": "Овог",
  "firstname": "Нэр",
  "_register_number": "Регистрийн дугаар",
  "age": "Нас",
  "gender": "Хүйс",
  "phone_number": "Утасны дугаар",
  "email_adddress": "И-Мэйл хаяг",
  "city": "Аймаг / Нийслэл",
  "facebook_address": "Фейсбүүк хаяг",
  "district": "Сум / дүүрэг",
  "_address": "Хаяг",
  "status": "Төлөв",
  "school": "Сургууль",
  "profession_studied": "Суралцсан мэргэжил",
  "duration": "Суралцсан хугацаа",
  "professional_level": "Мэргэжлийн түвшин",
  "start_date": "Элссэн огноо",
  "end_date": "Төгссөн огноо",
  "high_school": "Ерөнхий боловсролын сургууль",
};
