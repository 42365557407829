<template>
  <div id="app">
    <Header v-if="!routeAuth" />
    <router-view  :key="$route.path"></router-view>
    <Footer v-if="!routeAuth"/>
  </div>
</template>
<script>
import Header from '@/components/header.vue'
import Footer from '@/components/footer.vue'
import { mapGetters } from 'vuex';
export default {
  name: 'App',
  components: {
    Header: Header,
    Footer: Footer
  },
  data () {
    return {
      routeAuth: false
    }
  },
  computed: {
    ...mapGetters([
      'user',
      'userToken'
    ])
  },
  mounted () {
    this.routeAuth = this.$route.path.includes('login');
    googleTranslateElementInit();
  },
  created() {
    if(this.userToken != null && this.userToken != 'null' && this.userToken != ''){
      window.init.user = this.user;
      window.axios.defaults.headers.common['Authorization'] = 'Bearer '+this.userToken;
    }
  },
  watch: {
    '$route.path': function () {
      if (this.$route.path.includes('login')) {
        this.routeAuth = true
      } else {
        this.routeAuth = false
      }
      console.log(this.routeAuth)
    }
  }
}
</script>
