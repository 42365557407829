import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import iView from 'iview'
import './scss/style.scss'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import locale from 'iview/src/locale/lang/mn-MN'
import VueApollo from 'vue-apollo'
import 'swiper/swiper.scss'
import 'swiper/swiper-bundle.css'
import Swiper, { Navigation, Pagination } from 'swiper'
import VueYouTubeEmbed from 'vue-youtube-embed'
import { apolloClient } from './vue-apollo'
import vuexI18n from "vuex-i18n";
import Multiselect from 'vue-multiselect'
import en from "./locales/en";
import mn from "./locales/mn";

import {getDate} from "./utils/date";

/* starting Dataform */
import lodash from 'lodash';
import axios from 'axios';
window._ = lodash;
window.Vue = Vue;

//axios.defaults.baseURL = 'http://103.50.206.185';
axios.defaults.baseURL = 'https://edu-track.gov.mn';

window.axios = axios;
Vue.prototype.$http = axios;
Vue.prototype.$static_words = undefined;
/* ending Dataform */
Vue.use(vuexI18n.plugin, store);
Vue.i18n.add("en", en);
Vue.i18n.add("mn", mn);
Vue.i18n.set('mn');

Vue.component('multiselect', Multiselect)
Vue.use(VueAwesomeSwiper)
Vue.use(VueYouTubeEmbed)
Vue.use(VueApollo)
Swiper.use([Navigation, Pagination])
Vue.config.productionTip = false

const apolloProvider = new VueApollo({
  defaultClient: apolloClient
})
Vue.use(iView, {locale})
Vue.filter("date", (value) => {
  return getDate(value);
});

new Vue({
  router,
  store,
  apolloProvider,
  render: h => h(App)
}).$mount('#app')
