<template>
  <header id="topnav" class="defaultscroll sticky">
    <div class="container">
      <!-- Logo container-->
      <router-link class="logo" to="/">
        <span class="logo-light-mode">
          <img src="../assets/new_logo_5.svg" class="l-dark" alt="">
          <img src="../assets/new_logo_5.svg" class="l-light" alt="">
        </span>
<!--        <span class="logo-light-mode" v-if="language.short == 'mn'">-->
<!--          <img src="../assets/yam_logo.svg" class="l-dark"  alt="">-->
<!--          <img src="../assets/yam_logo.svg" class="l-light"  alt="">-->
<!--        </span>-->
<!--        <span class="logo-light-mode" v-else>-->
<!--          <img src="../assets/yam_logo_eng.svg" class="l-dark"  alt="">-->
<!--          <img src="../assets/yam_logo_eng.svg" class="l-light"  alt="">-->
<!--        </span>-->

<!--        <img src="../assets/yam_logo.svg" height="44" class="logo-dark-mode" alt="">-->
      </router-link>
      <div class="menu-extras">
        <div class="menu-item">
          <!-- Mobile menu toggle-->
          <a class="navbar-toggle" id="isToggle" @click="toggleMenu">
            <div class="lines">
              <span></span>
              <span></span>
              <span></span>
            </div>
          </a>
          <!-- End mobile menu toggle-->
        </div>
      </div>
      <ul class="login-button list-inline mb-0 d-none d-sm-block">
        <li class="list-inline-item ps-1 mb-0" v-if="userToken==null || userToken== 'null' || userToken==false">
          <router-link to="/login">
            <div class="login-btn-primary"><span class="btn btn-outline-primary">
              {{ 'login' | translate }}
            </span></div>
            <div class="login-btn-light"><span class="btn btn-outline-light">
              {{ 'login' | translate }}
            </span></div>
          </router-link>
        </li>
      </ul>
      <div id="navigation">
        <!-- Navigation Menu-->
        <ul class="navigation-menu nav-light">
          <li><router-link to="/" class="sub-menu-item">{{ 'home' | translate }}</router-link></li>
          <li v-if="userToken != null && userToken != false && user">
            <router-link to="/survey-list" class="sub-menu-item">{{ 'survey' | translate }}</router-link>
          </li>
<!--          <li>-->
<!--            <router-link to="/contact" class="sub-menu-item">{{ 'contact' | translate }}</router-link>-->
<!--          </li>-->
          <li><a href="/auth/login" class="sub-menu-item" target="_blank">{{ 'system_management' | translate }}</a></li>


<!--          <li v-for="locale in languages" :key="locale.index"-->
<!--              v-show="locale.short != language.short" @click="changeLocale(locale)">-->
<!--            <a >{{ locale.long }}</a></li>-->
          <li>
            <div id="google_translate_element"></div>
          </li>
          <li class="has-submenu parent-menu-item" v-if="userToken != null && userToken != false && user">
            <a href="javascript:void(0)">{{ user.first_name }}</a><span class="menu-arrow"></span>
            <ul class="submenu">
              <li><a  href="javascript:void(0)" @click="huviinMedeelel = true" class="sub-menu-item" style="text-transform: none">Хувийн мэдээлэл</a></li>
              <li><a href="javascript:void(0)" @click="logout()" class="sub-menu-item" style="text-transform: none">Системээс гарах</a></li>
            </ul>
          </li>
        </ul><!--end navigation menu-->
      </div><!--end navigation-->
      <Drawer width="640" v-model="huviinMedeelel" title="Хувийн мэдээлэл">
        <div>
           <div class="desc">
             <div class="row">
               <dataform ref="HuviinMedeelelform" :editMode="information_id >= 1" v-if="ready" :public="true" :onReady="readyInformationForm" schemaID="338"
                         :onSuccess="onSuccessAnketInformationForm" :onError="onError"/>
             </div>
           </div>
         </div>
      </Drawer>


    </div><!--end container-->

  </header>
</template>

<script>
import {toggleMenu, windowScroll} from "../utils/web";
import {mapGetters} from 'vuex';
import Vue from 'vue';
import { GET_Huviin_medeelel } from '../graphql/queries'
const Dataform = () => import(/* webpackChunkName: "Dataform-el" */'@lambda-platform/dataform/src/Dataform.vue')

export default {
  name: 'HelloWorld',
  data () {
    return {
      huviinMedeelel: false,
      ready: false,
      loading: true,

    }
  },
  props: {
    msg: String
  },
  components: {
    "dataform": Dataform,
  },
  computed: {
    ...mapGetters([
      'languages',
      'language',
      'user',
      'userToken',
      'information_id',
      // ...
    ]),
  },
  methods: {
    onError() {
      this.$Notice.error({
        title: this.$t("error"),
        desc: this.$t("error_occurred_submitting")
      });
    },
    onSuccessAnketInformationForm(data) {
      // this.$Notice.success({
      //   title: 'Амжилттай',
      //   desc: 'Таны мэдээлэл амжилттай илгээгдлээ'
      // });
      this.$store.commit("setInformationID", data.id);
      this.huviinMedeelel = false;
    },
    readyInformationForm() {
      if (this.information_id >= 1) {
        this.$refs.HuviinMedeelelform.editModel(this.information_id);
      }
    },
    getSavedHuviinmedeelel() {
      this.ready = false;
      this.$apollo.query({
        query: GET_Huviin_medeelel,
        variables: {
          user_id: this.user.id,
        },
      }).then(({data}) => {
        if (data.ds_view_huviin_medeelel.length >= 1) {
          this.$store.commit("setInformationID", data.ds_view_huviin_medeelel[0].id);
        }
        this.ready = true;
      })
    },
    logout() {
      localStorage.removeItem('user_token');
      localStorage.removeItem('user');
      this.$store.commit('setStorageValues');
      this.$router.push("/")
      window.location.reload();
    },
    toggleMenu: toggleMenu,
    changeLocale(locale) {
      Vue.i18n.set(locale.short);
      this.$store.commit('setLanguage', locale);
    },
  },
  mounted () {
    window.addEventListener('scroll', (ev) => {
      ev.preventDefault()
      windowScroll()
    });
    if (this.user.id) {
      this.getSavedHuviinmedeelel();
    }
  }
}
</script>
