<template>
  <footer id="footer">
    <div class="container">
      <div class="head_footer">
        <div class="row">
          <div v-if="loading" class="loading-wrap">
            <div class="lds-ellipsis">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
          <div class="col-md-6 col-sm-12 ">
            <div class="footer_details">
              <a target="_blank" href="#">
                <img src="../assets/yam_logo.svg" class="l-dark" alt="">
              </a>
              <a target="_blank" href="#">
                <img src="../assets/bolovsor_yam.svg" class="l-dark" alt="">
              </a>
              <p class="detail_text">
                Хөдөлмөр эрхлэлт, хөдөлмөрийн харилцаа, нийгмийн хамгаалал болон хүн амын хөгжлийн бодлогын суурь
                судалгаа, шинжилгээ, зөвлөмж боловсруулах чиг үүргийг хэрэгжүүлдэг төрийн байгууллага юм.
              </p>
              <a target="_blank" href="#">
                <img src="../assets/European_Union.svg" class="l-dark" alt="">
              </a>
            </div>
          </div>
          <div class="col-md-2 col-sm-12 " >
            <div class="footer_titles">Үндсэн цэс</div>
            <div class="systemiin_xandalt_cont">
              <div class="row">
                <ul>
                  <li>
                    <router-link to="/">Нүүр</router-link>
                  </li>
                  <li>
                    <router-link to="/survey-list">{{ 'survey' | translate }}</router-link>
                  </li>
                  <li>
                    <router-link to="/contact">{{ 'contact' | translate }}</router-link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-md-4 col-sm-12 ">
            <div class="footer_titles">{{ 'contact' | translate }}</div>
            <div class="footer_contact">
              <div class="row">
                <div class="col-md-12">
                  <p v-if="language.short == 'mn'">
                    <i class="ivu-icon ivu-icon-md-map"></i>
                    &nbsp;&nbsp; {{ contact.address }}
                  </p>
                  <p v-else>
                    <i class="ivu-icon ivu-icon-md-map"></i>
                    &nbsp;&nbsp; {{ contact.address_en }}
                  </p>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <p>
                    <i class="ivu-icon ivu-icon-md-mail"></i>
                    &nbsp;&nbsp; {{ contact.mail }}
                  </p>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <p v-if="language.short == 'mn'">
                    <i class="ivu-icon ivu-icon-md-call"></i>
                    &nbsp;&nbsp; {{ contact.phone_one }}
                  </p>
                  <p v-else>
                    <i class="ivu-icon ivu-icon-md-call"></i>
                    &nbsp;&nbsp; {{ contact.phone_one_en }}
                  </p>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <p v-if="language.short == 'mn'">
                    <i class="ivu-icon ivu-icon-md-call"></i>
                    &nbsp;&nbsp; {{ contact.phone_two }}
                  </p>
                  <p v-else>
                    <i class="ivu-icon ivu-icon-md-call"></i>
                    &nbsp;&nbsp; {{ contact.phone_two_en }}
                  </p>
                </div>
              </div>
            </div>
            <div class="footer_socials">
              <a target="_blank" :href="contact.facebook">
                <i class="ivu-icon ivu-icon-logo-facebook"></i>
              </a>
              <a target="_blank" :href="contact.twitter">
                <i class="ivu-icon ivu-icon-logo-twitter"></i>
              </a>
              <a target="_blank" :href="contact.instagram">
                <i class="ivu-icon ivu-icon-logo-instagram"></i>
              </a>
              <a target="_blank" :href="contact.web">
                <i class="ivu-icon ivu-icon-md-globe"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer_copyright">
      <div class="container">
        <div class="row">
          <div class="col-md-6">
            <div class="_left">
              {{ 'yam' | translate }}
            </div>
          </div>
          <div class="col-md-6">
            <div class="_right">
              <!--                    <ul>-->
              <!--                      <li><a href="">Үйлчилгээний нөхцөл</a></li>-->
              <!--                      <li><a href="">Нууцлалын бодлого</a></li>-->
              <!--                    </ul>-->
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import {mapGetters} from 'vuex';
import {GET_CONTACT} from "../graphql/queries";

export default {
  name: 'HelloWorld',
  data() {
    return {
      contact: {},
      loading: true
    }
  },
  computed: {
    ...mapGetters([
      'languages',
      'language',
      // ...
    ]),
  },
  mounted () {
    this.getContact()
  },
  methods: {
    getContact () {
      this.loading = true
      this.$apollo.query({ query: GET_CONTACT }).then(({ data }) => {
        this.contact = data.contact[0]
        setTimeout(() => {
          this.loading = false
        }, 1000)
      })
    }
  }
}
</script>
