<template>
  <div>

    <div class="row">
      <div class="col-12">

      </div>
    </div>

    <!-- mission_cont_bg --- erxem zorilgo -->
    <div class="mission_cont_bg">
        <div class="container">
          <div class="row">
            <div class="col-12 col-lg-6" v-if="language.short == 'mn'">
              <a class="mission_motto">{{ 'mission' | translate }}</a>
              <p class="mission_motto1"> {{home_text.title_mn}} </p>
              <p class="mission_motto2">{{home_text.sub_title_mn}}</p>
              <p class="misson_about">
                {{home_text.description_mn}}
              </p>
<!--              <p>-->
<!--                <Button class="btn_project_about">Төслийн тухай</Button>-->
<!--                </p>-->
            </div>
            <div class="col-12 col-lg-6" v-else>
              <a class="mission_motto">{{ 'mission' | translate }}</a>
              <p class="mission_motto1"> {{home_text.title_en}} </p>
              <p class="mission_motto2">{{home_text.sub_title_en}}</p>
              <p class="misson_about">
                {{home_text.description_en}}
              </p>
              <!--              <p>-->
              <!--                <Button class="btn_project_about">Төслийн тухай</Button>-->
              <!--                </p>-->
            </div>
            <div class="col-12 col-lg-6">
              <div class="mission_logo">
                <img src="../assets/img/home.svg" alt="">
              </div>
            </div>
          </div>
        </div>
    </div>

    <!-- project_about_cont - tusliin tuxai-->
    <div class="project_about_cont">
        <div class="container">
          <div v-if="loading" class="loading-wrap">
            <div class="lds-ellipsis">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-6">
              <div class="left_text" v-if="language.short == 'mn'">
                <p class="text1">{{aboutp.sub_header}}</p>
                <p class="text2_title">{{aboutp.Header}}</p>
                <p class="text3" v-html="aboutp.about"></p>
<!--                <p class="text3"> Програм хангамж нь дараах байдлаар ажиллана.</p>-->
                <ul class="text_list" v-html="aboutp.servive">
<!--                  <li>Автомат судалгаа үүсгэх</li>-->
<!--                  <li>Уламжлалт буюу гар аргаар үүсгэж, биечлэн, утсаар и-мэйлээр гэх мэт сувгаар дамжуулан судалгаа авах</li>-->
<!--                  <li>График, хүснэгтэн хэлбэрээр нийтэд болон тусгай эрхтэй хэрэглэгчдэд харуулдаг</li>-->
                </ul>
              </div>

              <div class="left_text" v-else>
                <p class="text1">{{aboutp.sub_header_en}}</p>
                <p class="text2_title">{{aboutp.header_en}}</p>
                <p class="text3" v-html="aboutp.about_en"></p>
                <ul class="text_list" v-html="aboutp.servive_en"></ul>
              </div>
            </div>
            <div class="col-12 col-md-6 ">
              <div class="right_video">
                <div class="o_column">
                  <div class="survey-item">
                    <img  src="../assets/img/survey_01.jpeg" class="survey-image" alt="building-176932_1280">
                    <div class="noo-survey-ds">
                      <a href="javascript:void(0);" @click="vide01 = true">
                        <img  src="../assets/img/playbtn.svg" >
                      </a>
                    </div>
                  </div>
                </div>
                <div class="t_column">
                  <div class="t_column_video">
                    <div class="survey-item">
                      <img  src="../assets/img/survey_02.jpeg" class="survey-image" alt="building-176932_1280">
                      <div class="noo-survey-ds">
                        <a href="javascript:void(0);" @click="vide02 = true">
                          <img  src="../assets/img/playbtn.svg" >
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="t_column_video">
                    <div class="survey-item">
                      <img  src="../assets/img/survey_03.jpeg" class="survey-image" alt="building-176932_1280">
                      <div class="noo-survey-ds">
                        <a href="javascript:void(0);" @click="vide03 = true">
                          <img  src="../assets/img/playbtn.svg" >
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
<!--    <statistic></statistic>-->
    <Modal
      v-model="vide01"
      :styles="{top: '25%'}"
      footer-hide>
      <div class="video_modal" v-if="aboutp.video1">
        <youtube :video-id="getVideo(aboutp.video1)"  player-width="100%"></youtube>
      </div>
    </Modal>
    <Modal
      v-model="vide02"
      :styles="{top: '25%'}"
      footer-hide>
      <div class="video_modal" v-if="aboutp.video2">
        <youtube :video-id="getVideo(aboutp.video2)"  player-width="100%"></youtube>
      </div>
    </Modal>
    <Modal
      v-model="vide03"
      :styles="{top: '25%'}"
      footer-hide>
      <div class="video_modal" v-if="aboutp.video3">
        <youtube :video-id="getVideo(aboutp.video3)"  player-width="100%"></youtube>
      </div>
    </Modal>
  </div>
</template>

<script>
import { getIdFromURL } from 'vue-youtube-embed'
import { GET_ABOUT, GET_HOME_TEXT } from '../graphql/queries'
import { mapGetters } from 'vuex'
// const statistic = () => import(/* webpackChunkName: "survey_about" */ './section/statistic')
// import Tobii from "@midzer/tobii"
export default {
  name: 'Home',
  components: {
    // statistic: statistic
  },
  data () {
    return {
      vide01: false,
      vide02: false,
      vide03: false,
      getVideo: getIdFromURL,
      // videoId1: 'HbM71ebzJOk',
      // videoId2: 'nvB2vgw0wnw',
      // videoId3: 'YEnoPiA36yY',
      surveys: [],
      aboutp: {},
      home_text: {},
      contact: [],
      about_arr: [],
      loading: true
    }
  },
  computed: {
    ...mapGetters([
      'languages',
      'language',
      // ...
    ]),
  },
  mounted () {
    this.getABOUTP();
    this.getHome()
  },
  methods: {
    getABOUTP () {
      this.loading = true
      this.$apollo.query({ query: GET_ABOUT }).then(({ data }) => {
        this.aboutp = data.aboutp[0]
        setTimeout(() => {
          this.loading = false
        })
        //console.log(this.aboutp)
      })
    },
    getHome () {
      this.loading = true
      this.$apollo.query({ query: GET_HOME_TEXT }).then(({ data }) => {
        this.home_text = data.home_text[0]
        this.loading = false
      })
    }
  }
  // mounted () {
  //   const tobii = new Tobii()
  //   tobii.on('open', function (e) {
  //     if(e.detail.group === 'events')
  //       console.log('event: ' + 'open', e.detail)
  //   })
  //   tobii.on('previous', function (e) {
  //     if(e.detail.group === 'events')
  //       console.log('event: ' + 'previous', e.detail)
  //   })
  //   tobii.on('next', function (e) {
  //     if(e.detail.group === 'events')
  //       console.log('event: ' + 'next', e.detail)
  //   })
  //   tobii.on('close', function (e) {
  //     if(e.detail.group === 'events')
  //       console.log('event: ' + 'close', e.detail)
  //   })
  // }
}
</script>
