import { ApolloClient } from 'apollo-client'
import { createHttpLink } from 'apollo-link-http'
import { InMemoryCache } from 'apollo-cache-inmemory'

// HTTP connection to the API
const httpLink = createHttpLink({
  uri: 'https://edu-track.gov.mn/query'
  //uri: 'http://192.168.88.208:5555/query'
})

// Cache implementation
const cache = new InMemoryCache({
  addTypename: false
})

// Create the apollo client
export const apolloClient = new ApolloClient({
  link: httpLink,
  cache
})
