import gql from 'graphql-tag'


export const GET_SURVEY = gql`{
  survey{
    id
    created_at
    deleted_at
    description
    end_date
    start_date
    status_id
    survey_data
    take_type_id
    title
    type_id
    updated_at
  }
}
`;

export const GET_Huviin_medeelel = gql`
query ds_view_huviin_medeelel($user_id: String!){
  ds_view_huviin_medeelel(
    filters: [{ column: "user_id", condition: equals, value: $user_id }]
  ) {
    id
    aimag_id
    user_id
  }
}
`;

export const GET_ABOUT = gql`{
  aboutp{
    Header
    header_en
    about
    about_en
    about_description
    about_description_en
    created_at
    deleted_at
    home_title
    home_title_en
    id
    servive
    servive_en
    sub_header
    sub_header_en
    updated_at
    video1
    video2
    video3
  }
}
`;

export const GET_HOME_TEXT = gql`{
  home_text{
    description_en
    description_mn
    id
    image
    sub_title_en
    sub_title_mn
    title_en
    title_mn
  }
}
`;

export const GET_CONTACT = gql`{
  contact{
    address
    address_en
    created_at
    deleted_at
    facebook
    id
    instagram
    logo
    mail
    org_name
    phone_one
    phone_one_en
    phone_two
    phone_two_en
    twitter
    updated_at
    web
  }
}
`;

export const GET_SURVEY_PAGE = gql`
query paginate($page:Int!, $size:Int!, $status_id: String!){
  paginate(
       page: $page, size: $size,
       filters: [{ column: "status_id", condition: equals, value: $status_id }]
  )
  {
    page
    total
    last_page
    ds_view_survey {
      created_at
      deleted_at
      description
      end_date
      id
      start_date
      status
      status_id
      title_en
      description_en

      take_type_id
      title
      type_id
      updated_at
      ds_sub_survey_user_view{
        display_name
        first_name
        id
        last_name
        role_id
        survey_id
        user_id
      }
    }
   }
}
`;

export const GET_SURVEY_USER_ID = gql`
query ds_sub_survey_user_view($user_id: String!){
  ds_sub_survey_user_view(
    filters: [{ column: "user_id", condition: equals, value: $user_id }]
  ){
      display_name
      first_name
      id
      last_name
      survey_id
      user_id
  }
}
`;

export const GET_SURVEY_BY_ID = gql`
query survey($surveyId: String!){
  survey(
    filters: [{ column: "id", condition: equals, value: $surveyId }]
  ){
      created_at
      deleted_at
      description
      description_en
      end_date
      id
      start_date
      status_id
      survey_data
      take_type_id
      title
      title_en
      type_id
      updated_at
  }
}
`;

export const GET_SURVEY_RESULT_BY_ID = gql`
query survey_result($user_id: String!,  $s_id: String!,){
  survey_result(
    groupFilters: [
        {
            combine: and
            filters: [
                {column: "user_id", condition: equals, value: $user_id},
                {column: "survey_id", condition: equals, value: $s_id}
            ]
        }
    ]
  ){
      created_at
      id
      result
      survey_id
      user_id
  }
}
`;
export const GET_SURVEY_RESULTs_BY_ID = gql`
query survey_result($user_id: String!){
  survey_result(
    groupFilters: [
        {
            combine: and
            filters: [
                {column: "user_id", condition: equals, value: $user_id}

            ]
        }
    ]
  ){
      survey_id
      user_id
  }
}
`;

// SUDALGAA = SURVEY SAVE
export const SURVEY_SAVE = gql`
    mutation createSurveyResult($user_id: Int!, $survey_id: Int!, $result: String!){
        createSurveyResult(input:{ user_id:$user_id, survey_id:$survey_id, result: $result}){
            user_id
            survey_id
            id
            result
        }
    }
`
