export default {
  "home": "Home",
  "yam": "Ministry of Labour and Social Protection of Mongolia",
  "survey": "Survey",
  "contact": "Contact",
  "system_management": "System management",
  "login": "Login",
  "location_map": "LOCATIONS MAP",
  "email": "Email",
  "addrees_location": "Address location",
  "phone": "Phone",
  "fill_survey": "Fill out the survey",
  "actual_survey": "Actual survey",
  "optimal": "Optimal policy",
  "back": "Back",
  "enter_your_information": "Enter your registration information.",
  "witout_registration": "Without registration?",
  "register": "Register",
  "forget_password": "Forgot your password?",
  "registration_number": "Please note that the registration number must be written in Cyrillic only.",
  "personal_information": "Personal information",
  "date": "Date",
  "mission": "Mission",
  "statistic": "Statistic",

  // Personal information
  "lastname": "Lastname",
  "firstname": "Firstname",
  "_register_number": "Register number",
  "age": "Age",
  "gender": "Gender",
  "phone_number": "Phone number",
  "email_adddress": "Email address",
  "city": "Province / City",
  "facebook_address": "Facebook address",
  "district": "District",
  "_address": "Address",
  "status": "Status",
  "school": "School",
  "profession_studied": "Profession studied",
  "duration": "Duration of study",
  "professional_level": "Professional level",
  "start_date": "Date of enrollment",
  "end_date": "Date of completion",
  "high_school": "High school",
};
