import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex);

let state = {
  user_token: localStorage.getItem('user_token'),
  user: JSON.parse(localStorage.getItem('user')),
  language: null,
  languages: [
    {
      short: "mn",
      long: "Мон"
    },
    {
      short: "en",
      long: "Eng"
    },
  ],
  information_id:0,
};

state.language = state.languages[0];

const store = new Vuex.Store({
  state:state,
  mutations: {
    setLanguage(state, lang) {
      state.language = lang;
    },
    setUser(state, user) {
      state.user = user;
      window.init.user = user;
      localStorage.setItem('user', JSON.stringify(user));
    },
    setUserToken(state, token) {
      state.user_token = token;
      window.axios.defaults.headers.common['Authorization'] = 'Bearer '+token;
      localStorage.setItem('user_token', token);
    },
    setStorageValues(state) {
      state.user = JSON.parse(localStorage.getItem('user'));
      state.user_token = localStorage.getItem('user_token');
    },
    setInformationID(state, value){
      state.information_id = value
    },
  },
  actions: {
  },
  modules: {
  },
  getters:{
    languages(){
      return state.languages
    },
    language(){
      return state.language
    },
    user() {
      return state.user
    },
    userToken() {
      return state.user_token
    },
    information_id(){
      return state.information_id
    },
  },

})


export default store;
