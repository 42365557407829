import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: () => import(/* webpackChunkName: "tugsugch" */ '../views/statistic/tugsugch')
  },
  {
    path: '/hudulmur_erhlelt',
    component: () => import(/* webpackChunkName: "hudulmur_erhlelt" */ '../views/statistic/hudulmur_erhlelt')
  },
  {
    path: '/ndsh_tulult',
    component: () => import(/* webpackChunkName: "ndsh_tulult" */ '../views/statistic/ndsh_tulult')
  },
  {
    path: '/halamj',
    component: () => import(/* webpackChunkName: "halamj" */ '../views/statistic/halamj')
  },
  {
    path: '/UG_hudulmur_erhlelt',
    component: () => import(/* webpackChunkName: "UG_hudulmur_erhlelt" */ '../views/statistic/UG_hudulmur_erhlelt')
  },
  {
    path: '/UG_ndsh_tulult',
    component: () => import(/* webpackChunkName: "UG_ndsh_tulult" */ '../views/statistic/UG_ndsh_tulult')
  },
  {
    path: '/UG_tugsugch',
    component: () => import(/* webpackChunkName: "UG_tugsugch" */ '../views/statistic/UG_tugsugch')
  },
  {
    path: '/UG_halamj',
    component: () => import(/* webpackChunkName: "UG_halamj" */ '../views/statistic/UG_halamj')
  },
  {
    path: '/form-survey/:id',
    name: 'form_survey',
    props: true,
    component: () => import(/* webpackChunkName: "form_survey" */ '../views/form_survey.vue')
  },
  {
    path: '/survey-view/:id',
    name: 'survey-view',
    props: true,
    component: () => import(/* webpackChunkName: "form_survey" */ '../views/survey_view.vue')
  },
  {
    path: '/survey-list',
    name: 'List',
    component: () => import(/* webpackChunkName: "about" */ '../views/survey_list')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "about" */ '../components/login')
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import(/* webpackChunkName: "about" */ '../views/contact')
  },
  // {
  //   path: '/statistic',
  //   name: 'statistic',
  //   component: () => import(/* webpackChunkName: "about" */ '../views/statistic')
  // },
]

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior () {
    return { x: 0, y: 0 }
  }
})

export default router
